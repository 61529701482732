<template>
    <section
        ref="hero"
        class="content-hero"
        :class="{
            'content-hero--with-image': image || pending,
            'content-hero__content--top-left': (image || pending) && imagePosition === 'topLeft',
            'content-hero__content--bottom-right': (image || pending) && imagePosition === 'bottomRight',
            'content-hero__content--middle': (image || pending) && imagePosition === 'middle',
            'content-hero__content--wide': (image || pending) && imagePosition === 'wide',
        }"
        :style="{
            '--background-color': backgroundColor,
            '--text-color': textColor
        }"
    >
        <div
            ref="content"
            class="content-hero__wrapper"
            :class="`content-hero--${mediaType}`"
        >
            <div
                class="content-hero__content"
            >
                <div
                    v-if="!!$slots.title || pending"
                    class="content-hero__title"
                >
                    <Skeletor
                        v-if="pending"
                        width="100px"
                    />
                    <slot
                        v-else
                        name="title"
                    />
                </div>

                <h1
                    v-if="!!$slots.description || pending"
                    class="content-hero__description"
                >
                    <Skeletor
                        v-if="pending"
                    />
                    <slot
                        v-else
                        name="description"
                    />
                </h1>
            </div>

            <div
                v-if="!!$slots.caption || pending"
                class="content-hero__caption"
            >
                <Skeletor
                    v-if="pending"
                    width="250px"
                />

                <slot
                    v-else
                    name="caption"
                />
            </div>

            <div
                v-if="mediaType === 'video'"
                class="content-hero__video"
            >
                <BaseVideo
                    :mp4="videoMp4?.url"
                    :webp="videoWebM?.url"
                />
            </div>

            <div
                v-else-if="image || pending"
                ref="imageElements"
                class="content-hero__image"
            >
                <Skeletor
                    v-if="pending"
                    :shimmer="false"
                    height="100%"
                    width="100%"
                />
                <BaseImage
                    v-else-if="image"
                    :image="image"
                    :title="image.title"
                    :width="image.width"
                    :height="image.height"
                    :sizes="sizes"
                    loading="eager"
                    fetchpriority="high"
                />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';
import { useScrollAnimations } from '~/composables/useScrollAnimations';
import { Skeletor } from 'vue-skeletor';
import { color } from '~/assets/styles/css-variables';

defineOptions({
    name: 'ContentHero',
});

const { resolveColor, getTextColor } = useColorPalette();

interface Props {
    imagePosition?: string;
    backgroundColor?: keyof ColorPalette;
    image?: {
        title: string;
        width: number;
        height: number;
        [key: string]: any;
    };
    videoMp4?: {
        url: string;
        title: string;
    };
    videoWebM?: {
        url: string;
        title: string;
    };
    pending?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    backgroundColor: () => 'cream',
    sizes: '(min-width: 992px) 660px, 100vw',
    pending: () => false,
    image: undefined,
    videoMp4: undefined,
    videoWebM: undefined,
    imagePosition: () => 'topLeft',
});

const video = computed(() => {
    return props.videoMp4 || props.videoWebM;
});

const mediaType = computed(() => {
    if (video?.value?.url) {
        return 'video';
    }

    return 'image';
});


const backgroundColor = computed(() => resolveColor(props.backgroundColor));
const textColor = computed(() => getTextColor(props.backgroundColor));

const navigationBackgroundColor = computed(() => {
    // A light text color implies a dark background color, set a light background color for the navigation
    if (textColor.value === color.lightest || textColor.value === color.lime) {
        return 'var(--color-cream)';
    } else {
        return backgroundColor.value;
    }
});

/** Set a global accent color, so it may be re-used in for example the header bar background */
useHead({
    style: () => [
        `:root {
            --accent-color: ${backgroundColor?.value};
            --navigation-text-color: ${textColor?.value};
            --navigation-background: ${navigationBackgroundColor?.value};
        }`
    ]
});

// Return sizes for the image, based on the image position
const sizes = computed (() => {
    // col = 29vw
    // gutter = 6.5vw
    if (props.imagePosition === 'topLeft' || props.imagePosition === 'bottomRight') {
        // desk: 1 col + 1 gutter - 418px max
        // mobile: 2cols + 1 gutter
        return '(min-width: 1440px) 418px, (min-width: 768px) 35.5vw, 64.5vw';
    } else if (props.imagePosition === 'middle') {
        // desk: 1 col + 2 gutters - 605px max
        // mobile: 100vw - 2 gutters
        return '(min-width: 1440px) 605px, (min-width: 768px) 42vw, 77vw';
    } else {
        // desk: 2 cols + 2 gutters - 1022px max
        // mobile: 100vw - 1 gutter
        return '(min-width: 1440px) 1022px, (min-width: 768px) 71vw, 93.5vw';
    }
});

const hero = ref();
const imageElements = ref([]);
const content = ref();
onMounted(() => {
    const { parallax } = useScrollAnimations();
    parallax(
        content.value,
        {
            trigger: hero.value,
            offset: .6
        }
    );
});
</script>

<style lang="less" src="./ContentHero.less"></style>
